import React, { useState } from 'react';
import FBAccordionItem from './FBAccordionItem';
import './fbaccordion.scss'

const FBAccordion = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, i) => (
        <FBAccordionItem
          key={i}
          title={item.title}
          isOpen={i === openIndex}
          onClick={() => handleClick(i)}
        >
          <div dangerouslySetInnerHTML={{ __html: item.content }} />
        </FBAccordionItem>
      ))}
    </div>
  );
};

export default FBAccordion;