import React, { useState, useEffect, useRef } from 'react';

const FBParallaxSection = ({children, image, gradient, height = 500, intensity = 0.5, style}) => {

    const [backgroundPosition, setBackgroundPosition] = useState('center 0px');
    const [blurValue, setBlurValue] = useState(0);
    const parallaxRef = useRef(null);

    useEffect(() => {
        let animationFrameId;
    
        const handleScroll = () => {
            if (parallaxRef.current) {
                const offset = window.pageYOffset - parallaxRef.current.offsetTop;
                const newPosition = `center ${offset * intensity}px`;
                setBackgroundPosition(newPosition);
        
                const newBlurValue = Math.min(offset * 0.1, 20); // Adjust 0.1 to control rate of blur
                setBlurValue(newBlurValue);
            }
        };
    
        const onScroll = () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            animationFrameId = requestAnimationFrame(handleScroll);
        };
    
        window.addEventListener('scroll', onScroll);
    
        return () => {
            window.removeEventListener('scroll', onScroll);
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, []);

    const combinedStyle = {
        color: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        zIndex: 0,
        alignContent: 'center',
        backgroundSize: 'cover',
        paddingLeft:'20px',
        paddingRight:'20px',
        justifyContent: 'center',
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: backgroundPosition,
        height: `${height}px`,
        width: '100%',
        position: 'relative',
        ...style
    };

    return (
        <div
            ref={parallaxRef}
            style={combinedStyle}
        >
            <div style={{
                position: 'absolute',
                top: 0,
                zIndex: -1,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: gradient,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                pointerEvents: 'none'
            }} />
            {children}
        </div>
    );
    
    

};

export default FBParallaxSection;
