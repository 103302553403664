import React, { useState, useRef } from "react";
import {
  APIProvider,
  Map,
  Marker,
  AdvancedMarker,
  useAdvancedMarkerRef,
  InfoWindow,
  useMarkerRef,
} from "@vis.gl/react-google-maps";
import { MarkerWithInfowindow } from "./Marker";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 30,
  lng: 0,
};

const locations = [
  { lat: 40.7128, lng: -74.006, label: "New York", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 34.0522, lng: -118.2437, label: "Los Angeles", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 41.8781, lng: -87.6298, label: "Chicago, IL", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 38.9072, lng: -77.0369, label: "Washington, DC", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 27.9944, lng: -81.7603, label: "Florida", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 34.0489, lng: -111.0937, label: "Arizona", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 39.7684, lng: -86.1581, label: "Indiana", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 15.87, lng: 100.9925, label: "Thailand", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 51.1657, lng: 10.4515, label: "Germany", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 50.5039, lng: 4.4699, label: "Belgium", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 23.4241, lng: 53.8478, label: "United Arab Emirates", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: -25.2744, lng: 133.7751, label: "Australia", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 12.8797, lng: 121.774, label: "Philippines", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 17.1899, lng: -88.4976, label: "Belize", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 55.3781, lng: -3.436, label: "United Kingdom", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 31.0461, lng: 34.8516, label: "Israel", contact_name:"", contact_email:"info@conservativesabroad.ca" },
  { lat: 22.3193, lng: 114.1694, label: "Hong Kong", contact_name:"", contact_email:"info@conservativesabroad.ca" },
];

function MyGoogleMap() {
  const [infowindowOpen, setInfowindowOpen] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);
  const [markerRef, marker] = useMarkerRef();

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
    setInfowindowOpen(true);
  };

  const handleInfoWindowClose = () => {
    setInfowindowOpen(false);
    setActiveMarker(null);
  };

  let markers;
  if (locations) {
    markers = locations.map((location) => {
      return (
        <React.Fragment key={location.label}>
          <AdvancedMarker
            key={location.label}
            ref={markerRef}
            label={location.type}
            position={location.address}
          />
          {location.description ? (
            <InfoWindow anchor={marker} key={`${location.label}-iw`}>
              <p>{location.description}</p>
            </InfoWindow>
          ) : null}
        </React.Fragment>
      );
    });
  }

  console.log(activeMarker);

  return (
    <APIProvider apiKey="AIzaSyDqHHddruaHP5cSuywyVI02WY9WOSslw50">
      <Map
        mapId="a5c6c98ffff239f9"
        disableDefaultUI
        defaultCenter={center}
        defaultZoom={2}
        style={containerStyle}
      >
        {locations.map((location, i) => (
          <MarkerWithInfowindow key={i} the_index={i} setActiveMarker={setActiveMarker} activeMarker={activeMarker} lat={location.lat} lng={location.lng} label={location.label} contact_name={location.contact_name} contact_email={location.contact_email} />
        ))}
      </Map>
    </APIProvider>
  );
}

export default MyGoogleMap;
