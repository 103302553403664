import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { FBCarouselControlProvider } from './FBCarouselContext';
import FBCarouselItem from './FBCarouselItem';
import './fbcarousel.scss'
import { breakpointSm, breakpointMd, breakpointLg } from '../../../utils/utility';

const FBCarousel = ({ settings, slides, className, style, onSliderChange }) => {

    var breakpointLgValue = breakpointLg.replace('px', '') * 1;
    var breakpointMdValue = breakpointMd.replace('px', '') * 1;
    var breakpointSmValue = breakpointSm.replace('px', '') * 1;
    
    const [slidesToShow, setSlidesToShow] = useState(4);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > breakpointLgValue) {
          setSlidesToShow(4);
        } else if (window.innerWidth > breakpointMdValue && window.innerWidth <= breakpointLgValue) {
          setSlidesToShow(2.5);
        } else if (window.innerWidth > breakpointSmValue && window.innerWidth <= breakpointMdValue) {
          setSlidesToShow(1.8);
        } else if (window.innerWidth < breakpointSmValue) {
          setSlidesToShow(1);
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const defaultSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 5000,
        ...settings
      };

      const carouselRef = useRef();

      const renderSlides = () => {
        if (!slides || slides.length === 0) {
          return <div>No slides available</div>;
        }
    
        return slides.map((slide, i) => (
            <FBCarouselItem key={i} content={slide.content} />
        ));
      };
  
      return (
        <FBCarouselControlProvider carouselRef={carouselRef}>
            <div className={`fbcarousel ${className}`} style={style}>
                <Slider ref={carouselRef} {...defaultSettings} {...(onSliderChange && { beforeChange: onSliderChange })}>   
                    {renderSlides()}
                </Slider>
            </div>
        </FBCarouselControlProvider>
      );
  };

  FBCarousel.propTypes = {
    settings: PropTypes.object,
    slides: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.node.isRequired
    })),
    className: PropTypes.string,
    style: PropTypes.object,
    onCarouselChange: PropTypes.func
  };
  
  FBCarousel.defaultProps = {
    slides: [],
    className: '',
    style: {}
  };
  
  export default FBCarousel;