import React from 'react';
import { useSliderControl } from '../FlashbulbUI/FBSlider/FBSliderContext';
import { 
    FormWrapper, 
    TextInput, 
    PhoneInput, 
    FormGroup, 
    CountryInput, 
    SubmitButton 
} from '../FlashbulbUI/Form'
import { Container } from '../FlashbulbUI/Container';
import { FlashbulbFormProvider } from '../FlashbulbUI/Form';
import { Hero } from '../FlashbulbUI/Hero';


const TextSlide = ({children, image, style}) => {

    const { pauseSlider, playSlider } = useSliderControl();

    return (
        <Hero style={style} image={image ? image : "https://flashbulb-files.s3.amazonaws.com/images/canadianflag.jpg"}>
            {children}
        </Hero>
    );

}

export default TextSlide;