import React, { useContext } from "react";
import { Label, Select } from './index'
import { city_array } from "../../../utils/utility";
import { FormDataContext } from "../../FlashbulbFormProvider/FlashbulbFormProvider";

const CityInput = ({
    label, 
    className, 
    style,
    labelColor,
    required
}) => {

    const { 
        formData, 
        onBlurHandler, 
        onSelectHandler 
    } = useContext(FormDataContext);

    return (
        <span className={className} style={style}>
            <Label color={labelColor}>{label}</Label>
            <Select required={required === "true"} placeholder="" name="city" value={formData && formData.city ? formData.city : ""} onBlur={onBlurHandler} onChange={(e) => onSelectHandler(e, "city")}>
            <option value=""></option>
                {city_array.map((city, i) => (
                    <option key={i} value={city.code}>{city.city}</option>
                ))}
            </Select>
        </span>
    )

}

export default CityInput;