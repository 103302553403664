import React from 'react';
import Container from '../Container/Container';
import './hero.scss';

const Hero = ({
    className = '', 
    children, 
    id, 
    height, 
    image = 'https://flashbulb-files.s3.amazonaws.com/images/canadianflag.jpg',
    video,
    onClick,
    style
}) => {

    const baseStyle = {
        backgroundImage: image ? `linear-gradient(45deg, rgba(255, 255, 255, 0.6), rgba(232, 29, 45, 0.9), rgba(15, 45, 82, 0.9)), url(${image})` : undefined,
        backgroundPosition: 'center center',
        height: height ? `${height}px` : undefined,
    };
    

    const finalStyle = { ...baseStyle, ...style };

    return (
        <div className={`hero ${className}`} id={id} onClick={onClick} style={finalStyle}>
            {video && (
                <video autoPlay loop muted playsInline>
                    <source src={video} />
                </video>
            )}
            <Container>
                {children}
            </Container>
        </div>
    );
}

export default Hero;
