import React, { useEffect } from 'react';
import MarkdownComponent from '../../MarkdownComponent/MarkdownComponent';
import './pageContent.scss'
import { Container } from '../../FlashbulbUI/Container';
import { Hero, HeroTitle, HeroDate } from '../../FlashbulbUI/Hero';
import { Footer } from '../../Footer';
import ContentSection from '../../FlashbulbUI/Section/ContentSection';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

function formatDate(date) {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);

    const day = date.getDate();
    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    }

    return formattedDate.replace(/\d+/, day + suffix);
}

const ContentPage = ({pageData}) => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    return (
        <>
        <Hero>
            <HeroTitle type="page">{pageData.title}</HeroTitle>
            <HeroDate type="page">{formatDate(new Date(pageData.updated))}</HeroDate>
        </Hero>
        <ContentSection type="standard" size="sm">
            <div className="pageContent">
                <MarkdownComponent markdownText={pageData.body} />
            </div>
        </ContentSection>
        </>
    )

}

export default ContentPage;