import React from 'react';
import './form.scss';

const Label = ({children, style, color}) => {

    var labelStyle = style ? style : {};

    if (color == "white") {
        labelStyle.color = "white";
    }

    return (
        <div className="label" style={labelStyle}>
            {children}
        </div>
    )

}

export default Label;