import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import logo from '../../../assets/custom/cca-logo.png'

export const MarkerWithInfowindow = ({ lat, lng, label, contact_name, contact_email, activeMarker, setActiveMarker, the_index }) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => { setActiveMarker(the_index); setInfowindowOpen(true)}}
        position={{ lat: lat, lng: lng }}
        title={"AdvancedMarker that opens an Infowindow when clicked."}
      >
        <div
          style={{
            width: 16,
            height: 16,
            position: "absolute",
            top: 0,
            left: 0,
            background: '#264d7e',
            border: "2px solid #0f2d52",
            borderRadius: "50%",
            transform: "translate(-50%, -50%)",
          }}
        ></div>
      </AdvancedMarker>
      {activeMarker === the_index && infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={300}
          onCloseClick={() => setInfowindowOpen(false)}
        >
            <div style={{color:'black'}}>
                <h4>{label}</h4><br />
            Email: <a href={`mailto:${contact_email}`}>{contact_email}</a>
          </div>
        </InfoWindow>
      )}
    </>
  );
};
