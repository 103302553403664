import React from 'react';
import { website_content } from './utils/website';
import Home from './Home'
import NotFound from './NotFound';
import CategoryPage from './components/pages/CategoryPage/CategoryPage'
import ContentPage from './components/pages/ContentPage/ContentPage';
import { Routes, Route } from 'react-router-dom';
import FormParent from './components/pages/Forms/FormParent';
import { About } from './components/pages/About';
import { Vote } from './components/pages/Vote';
import { Thanks } from './components/pages/Thanks';
import { Events } from './components/pages/Events';

const DynamicRoutes = () => {

    return (
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                {website_content.map((category, index) => (
                    <React.Fragment key={index} >
                        { category.content.map((page, pageIndex) => (
                            <Route
                                key={`${index}-${pageIndex}`}
                                path={`/${category.slug}/${page.slug}`}
                                element={<ContentPage pageData={page} />}
                            />
                        ))}
                        <Route
                            key={`category-${index}`}
                            path={`/${category.slug}`}
                            element={<CategoryPage categoryData={category} />}
                        />
                    </React.Fragment>
                ))}
                <Route path="/about" element={<About />} />
                <Route path="/vote" element={<Vote />} />
                <Route path="/events" element={<Events />} />
                <Route path="/thanks" element={<Thanks />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
    )

}

export default DynamicRoutes;