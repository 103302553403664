import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';
import history from '../../utils/history'
import { validateEmail, validatePhoneNumber, validatePostalCode } from '../../utils/utility';
import { useNavigate } from 'react-router-dom';

export const FormDataContext = createContext(null);

const Form = (
    {
        children,
        className,
        form_id,
        onSubmit,
        action,
        method,
        encType,
        target,
        name,
        noValidate,
        autoComplete,
        acceptCharset,
        rel,
        style,
        onReset
    }
    ) => {

    let navigate = useNavigate();

    const [formData, setFormData] = useState({
        fname:"",
        lname:"",
        email:"",
        phone_country_code:"",
        phone:"",
        zip:"",
        state:"",
        country:"",
        city:"",
        address:""
    });
    const [errors, setErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await axios.post('https://action1.docked.ca/ccajoin',{ 
            first_name: formData.fname,
            last_name: formData.lname,
            email: formData.email,
            city: formData.city,
            country: formData.country
        });
        navigate('/thanks')
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({...prevData, [name]: value }));
        if (formData.country !== "") {
            if (!validatePostalCode(value, formData.country)) {
                setErrors(prevData => ({...prevData, zip: true }))
            } else {
                setErrors(prevData => ({...prevData, zip: false }))
            }
        }
    }
    
    const onBlurHandler = (event) => {
        const { name, value } = event.target;

        if (name === "fname") {
            if (value.length == 0) {
                setErrors(prevData => ({...prevData, fname: true }))
            } else {
                setErrors(prevData => ({...prevData, fname: false }))
            }
        }

        if (name === "lname") {
            if (value.length == 0) {
                setErrors(prevData => ({...prevData, lname: true }))
            } else {
                setErrors(prevData => ({...prevData, lname: false }))
            }
        }

        if (name === "email") {
            if (!validateEmail(value)) {
                setErrors(prevData => ({...prevData, email: true }))
            } else {
                setErrors(prevData => ({...prevData, email: false }))
            }
        }
        if (name === "phone_country_code") {
            setFormData(prevData => ({...prevData, phone_country_code: value}));
        }
        if (name === "phone") {
            console.log(value)
            if (!validatePhoneNumber(value)) {
                setErrors(prevData => ({...prevData, phone: true }))
            } else {
                setErrors(prevData => ({...prevData, phone: false }))
            }
        }
        if (name === "zip") {
            if (formData.country !== "") {
                if (!validatePostalCode(value, formData.country)) {
                    setErrors(prevData => ({...prevData, zip: true }))
                } else {
                    setErrors(prevData => ({...prevData, zip: false }))
                }
            }
        }
        if (formData != null && formData.zip != null && formData.zip !== "" && name === "country") {
            if (!validatePostalCode(formData.zip, value)) {
                console.log("X")
                setErrors(prevData => ({...prevData, zip: true }))
            } else {
                console.log("Y")
                setErrors(prevData => ({...prevData, zip: false }))
            }
        }
    }
    
    const onSelectHandler = (event, name) => {
        console.log(event.target.options[event.target.selectedIndex].value)
        if (name === "country") {
            if (formData.zip != null) {
                if (!validatePostalCode(formData.zip, event.target.options[event.target.selectedIndex].value)) {
                    setErrors(prevData => ({...prevData, zip: true }))
                } else {
                    setErrors(prevData => ({...prevData, zip: false }))
                }
            }        
            setFormData(prevData => ({...prevData, country: event.target.options[event.target.selectedIndex].value }))
            }
        if (name === "city") {
            setFormData(prevData => ({...prevData, city: event.target.options[event.target.selectedIndex].value }))
        }
    }
    
    const onSubmitHandler = async (event) => {
        if (formData != null && formData.fname != '' && formData.lname != '' && validateEmail(formData.email) && formData.country != '') {
            event.preventDefault();
            var response = await handleSubmit(event)
        } else {
            event.preventDefault();
        }
    };

    const formDataValue = { formData, setFormData, onChangeHandler, onBlurHandler, onSelectHandler, onSubmitHandler, errors, setErrors };

    return (
        <FormDataContext.Provider value={formDataValue}>
            <form
                className={`form ${className}`}
                action={action} 
                method={method} 
                encType={encType} 
                target={target} 
                name={name}
                noValidate={noValidate} 
                autoComplete={autoComplete} 
                acceptCharset={acceptCharset} 
                rel={rel} 
                onReset={onReset} 
                style={style}
                onSubmit={handleSubmit}
            >
                {children}
            </form>
        </FormDataContext.Provider>
    )

}

export default Form;