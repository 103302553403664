import React from 'react';
import './form.scss';

const Select = ({children, required, onChange, onBlur, value, name, style}) => {

    return (
        <select required={required} name={name} value={value} onChange={onChange} onBlur={onBlur} className="select" style={style}>
            {children}
        </select>
    )

}

export default Select;