import React from 'react';
import { useSliderControl } from './FBSliderContext';

const FBSlide = ({ content, style }) => {
  const { pauseSlider, playSlider } = useSliderControl();

  return (
    <div style={style}>
      {content}
    </div>
  );
};

export default FBSlide;
