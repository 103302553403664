import React from 'react';
import './hero.scss';

const HeroTitle = ({children, type}) => {

    return (
        <>
        {type === "home" ? 
        <div className="heroTitleHome">{children}</div>
        :
        <div className="heroTitlePage">{children}</div>
        }
        </>
    )

}

export default HeroTitle;