import React from 'react';
import './layout.scss';

const Col = ({
    children, 
    style,
    xs, 
    sm, 
    md, 
    lg, 
    xl
}) => {

    xs = xs || "12";
    sm = sm || xs;
    md = md || sm;
    lg = lg || md;
    xl = xl || lg;
    
    const layoutStyle = [
        `col-xl-${xl}`,
        `col-lg-${lg}`,
        `col-md-${md}`,
        `col-sm-${sm}`,
        `col-xs-${xs}`
    ];

    return (
        <div className={layoutStyle.join(' ')} style={style}>
            {children}
        </div>
    )

}

export default Col;