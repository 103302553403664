import React, { createContext, useContext } from 'react';

const SliderControlContext = createContext();

export const useSliderControl = () => useContext(SliderControlContext);

export const SliderControlProvider = ({ children, sliderRef }) => {
  const pauseSlider = () => {
    sliderRef.current.slickPause();
  };

  const playSlider = () => {
    sliderRef.current.slickPlay();
  };

  return (
    <SliderControlContext.Provider value={{ pauseSlider, playSlider }}>
      {children}
    </SliderControlContext.Provider>
  );
};
