import React from 'react';
import './container.scss'

const Container = ({children, className, size}) => {

    return(
        <div className={`${className} ${size === "sm" ? "container" : "container-large"}`}>
            {children}
        </div>
    )
}

export default Container;