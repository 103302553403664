import React, { useState, useEffect, useContext } from "react";
import { Label, Input, ErrorLabel } from './index'
import { FormDataContext } from "../../FlashbulbFormProvider/FlashbulbFormProvider";
import { countries_array } from "../../../utils/utility";

const TextInput = ({
        className, 
        errorText, 
        label, 
        placeholder, 
        dataType, 
        required, 
        labelColor,
        style
    }) => {

    const { 
        formData, 
        onBlurHandler, 
        onChangeHandler, 
        errors 
    } = useContext(FormDataContext);

    const [countryCount, setCountryCount] = useState(0)

    useEffect(() => {
        setCountryCount(prevCountryCount => prevCountryCount + 1);
    }, [formData.country]);

    return (
        <>
            <span className={className}>
                <Label color={labelColor}>
                    {label}
                </Label>
                <Input 
                    className={`${errors[dataType] ? 'error' : ''}`} 
                    placeholder={placeholder} 
                    name={dataType} 
                    value={formData[dataType]} 
                    style={style} 
                    onBlur={onBlurHandler} 
                    onChange={onChangeHandler} 
                    required={required === "true"} 
                />
                { errors[dataType] ? 
                    <ErrorLabel 
                        className={errors[dataType] ? 'error' : ''}>
                            {errorText} {dataType === "zip" && countryCount && formData && formData.country ? countries_array.filter((country) => country.country_code === formData.country)[0].flag : ""}
                        </ErrorLabel> 
                    : 
                    <></> 
                }
            </span>
        </>
    )

}

export default TextInput;