export const stripMarkdown = (markdownText) => {
    const regex = /(\*\*|__)(.*?)\1|(\*\*|__)(.*?)\1|!\[.*?\]\(.*?\)|\[.*?\]\(.*?\)|!\[(.*?)\]\[(.*?)\]|\[(.*?)\]\[(.*?)\]|(`{3,})(.*?)\1|`{1,2}(.*?)`|\*\*(.*?)\*\*|\*(.*?)\*|__(.*?)__|_(.*?)_|~~(.*?)~~|(\r\n|\r|\n)>[ \t]*(.*?)(\r\n|\r|\n)|\n-{5,}|(#+)(.*?)\n/g;
    return markdownText.replace(regex, '');
}

export const validateEmail = (input) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(input);
};

export const validatePhoneNumber = (input) => {
    input = input.replace(/[()\-\s.]/g, '');
    const phoneRegex = /^(\+{0,1}\d{1,2}\s?)?(\(\d{2,3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(input);
};

export const validatePostalCode = (postalCode, countryCode) => {
    const patterns = {
        "US": /^\d{5}(-\d{4})?$/,                    // United States: 12345 or 12345-1234
        "CA": /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/, // Canada: A1A 1A1 or A1A1A1
        "GB": /^[A-Za-z]{1,2}\d[A-Za-z\d]?\s?\d[A-Za-z]{2}$/, // United Kingdom: AA1 1AA, A1 1AA, or AA11AA
        "DE": /^\d{5}$/,                             // Germany: 12345
        "FR": /^\d{5}$/,                             // France: 12345
        "IT": /^\d{5}$/,                             // Italy: 12345
        "AU": /^\d{4}$/,                             // Australia: 1234
        "JP": /^\d{3}-\d{4}$/,                       // Japan: 123-4567
        "BR": /^\d{5}-\d{3}$/,                       // Brazil: 12345-123
        "IN": /^\d{6}$/,                             // India: 123456
        "CN": /^\d{6}$/,                             // China: 123456
        "KR": /^\d{5}$/,                             // South Korea: 12345
        "SE": /^\d{3}\s?\d{2}$/,                     // Sweden: 123 45 or 12345
        "ZA": /^\d{4}$/,                             // South Africa: 1234
        "NL": /^\d{4}\s?[A-Za-z]{2}$/,               // Netherlands: 1234 AB or 1234AB
        "ES": /^\d{5}$/,                             // Spain: 12345
        "DK": /^\d{4}$/,                             // Denmark: 1234
        "BE": /^\d{4}$/,                             // Belgium: 1234
        "RU": /^\d{6}$/,                             // Russia: 123456
        "NO": /^\d{4}$/,                             // Norway: 1234
        "FI": /^\d{5}$/,                             // Finland: 12345
        "PL": /^\d{2}-\d{3}$/,                       // Poland: 12-345
        "PT": /^\d{4}-\d{3}$/,                       // Portugal: 1234-567
        "GR": /^\d{3}\s?\d{2}$/,                     // Greece: 123 45 or 12345
        "MX": /^\d{5}$/,                             // Mexico: 12345
        "AR": /^\d{4}$/,                             // Argentina: 1234
        "CH": /^\d{4}$/,                             // Switzerland: 1234
        "AT": /^\d{4}$/,                             // Austria: 1234
        "IE": /^[A-Za-z]\d{1,2}\s?[A-Za-z]{1,2}$/,   // Ireland: D02 X285 or D02X285
        "NZ": /^\d{4}$/,                             // New Zealand: 1234
        "CZ": /^\d{3}\s?\d{2}$/,                     // Czech Republic: 123 45 or 12345
        "SK": /^\d{3}\s?\d{2}$/,                     // Slovakia: 123 45 or 12345
        "HU": /^\d{4}$/,                             // Hungary: 1234
        "TR": /^\d{5}$/,                           // Turkey: 12345
        "EG": /^\d{5}$/,                           // Egypt: 12345
        "SA": /^\d{5}$/,                           // Saudi Arabia: 12345
        "AE": /^\d{5}$/,                           // United Arab Emirates: 12345
        "TH": /^\d{5}$/,                           // Thailand: 12345
        "SG": /^\d{6}$/,                           // Singapore: 123456
        "MY": /^\d{5}$/,                           // Malaysia: 12345
        "PH": /^\d{4}$/,                           // Philippines: 1234
        "ID": /^\d{5}$/,                           // Indonesia: 12345
        "NG": /^\d{6}$/,                           // Nigeria: 123456
        "KE": /^\d{5}$/,                           // Kenya: 12345
        "GH": /^\d{5}$/,                           // Ghana: 12345
        "PK": /^\d{5}$/,                           // Pakistan: 12345
        "BD": /^\d{4}$/,                           // Bangladesh: 1234
        "UA": /^\d{5}$/,                           // Ukraine: 12345
        "RO": /^\d{6}$/,                           // Romania: 123456
        "BG": /^\d{4}$/,                           // Bulgaria: 1234
        "RS": /^\d{5}$/,                           // Serbia: 12345
        "HR": /^\d{5}$/,                           // Croatia: 12345
        "SI": /^\d{4}$/,                           // Slovenia: 1234
        "LV": /^\d{4}$/,                           // Latvia: 1234
        "LT": /^\d{5}$/,                           // Lithuania: 12345
        "EE": /^\d{5}$/,                           // Estonia: 12345
        "CY": /^\d{4}$/,                           // Cyprus: 1234
        "MT": /^[A-Za-z]{3}\s?\d{4}$/,             // Malta: ABC 1234 or ABC1234
        "IS": /^\d{3}$/,                           // Iceland: 123
        "LU": /^\d{4}$/,                           // Luxembourg: 1234
        "MC": /^\d{5}$/,                           // Monaco: 12345
        "AD": /^AD\d{3}$/,                         // Andorra: AD123
        "LI": /^\d{4}$/,                           // Liechtenstein: 1234
        "SM": /^\d{5}$/,                           // San Marino: 12345
        "VA": /^00120$/,                           // Vatican: 00120
        "BY": /^\d{6}$/,                           // Belarus: 123456
        "MD": /^\d{4}$/,                           // Moldova: 1234
        "AM": /^\d{4}$/,                           // Armenia: 1234
        "AZ": /^\d{4}$/,                           // Azerbaijan: 1234
        "GE": /^\d{4}$/,                           // Georgia: 1234
        "KZ": /^\d{6}$/,                           // Kazakhstan: 123456
        "UZ": /^\d{6}$/,                           // Uzbekistan: 123456
        "TM": /^\d{6}$/                           // Turkmenistan: 123456
    };

    const regex = patterns[countryCode];
    
    if (regex) {
        return regex.test(postalCode);
    } else {
        return true; // don't validate
    }
}

export const city_array = [
    {city:'New York', code:'NY'},
    {city:'Los Angeles', code:'LA'},
    {city:'Washington DC', code:'DC'},
    {city:'San Francisco', code:'SF'},
    {city:'Chicago', code:'CH'},
    {city:'Indianapolis', code:'IN'},
    {city:'Florida', code:'FL'},
    {city:'Arizona', code:'AZ'}
];

export const countries_array = [
    {phone: "+1", country: "Canada", flag: "🇨🇦", country_code: "CA"},
    {phone: "+1", country: "United States", flag: "🇺🇸", country_code: "US"},
    {phone: "+44", country: "United Kingdom", flag: "🇬🇧", country_code: "GB"},
    {phone: "+61", country: "Australia", flag: "🇦🇺", country_code: "AU"},
    {phone: "+353", country: "Ireland", flag: "🇮🇪", country_code: "IE"},
    {phone: "+64", country: "New Zealand", flag: "🇳🇿", country_code: "NZ"},
    {phone: "+33", country: "France", flag: "🇫🇷", country_code: "FR"},
    {phone: "+93", country: "Afghanistan", flag: "🇦🇫", country_code: "AF"},
    {phone: "+355", country: "Albania", flag: "🇦🇱", country_code: "AL"},
    {phone: "+213", country: "Algeria", flag: "🇩🇿", country_code: "DZ"},
    {phone: "+376", country: "Andorra", flag: "🇦🇩", country_code: "AD"},
    {phone: "+244", country: "Angola", flag: "🇦🇴", country_code: "AO"},
    {phone: "+1", country: "Antigua and Barbuda", flag: "🇦🇬", country_code: "AG"},
    {phone: "+54", country: "Argentina", flag: "🇦🇷", country_code: "AR"},
    {phone: "+374", country: "Armenia", flag: "🇦🇲", country_code: "AM"},
    {phone: "+43", country: "Austria", flag: "🇦🇹", country_code: "AT"},
    {phone: "+994", country: "Azerbaijan", flag: "🇦🇿", country_code: "AZ"},
    {phone: "+1", country: "Bahamas", flag: "🇧🇸", country_code: "BS"},
    {phone: "+973", country: "Bahrain", flag: "🇧🇭", country_code: "BH"},
    {phone: "+880", country: "Bangladesh", flag: "🇧🇩", country_code: "BD"},
    {phone: "+1", country: "Barbados", flag: "🇧🇧", country_code: "BB"},
    {phone: "+375", country: "Belarus", flag: "🇧🇾", country_code: "BY"},
    {phone: "+32", country: "Belgium", flag: "🇧🇪", country_code: "BE"},
    {phone: "+501", country: "Belize", flag: "🇧🇿", country_code: "BZ"},
    {phone: "+229", country: "Benin", flag: "🇧🇯", country_code: "BJ"},
    {phone: "+975", country: "Bhutan", flag: "🇧🇹", country_code: "BT"},
    {phone: "+591", country: "Bolivia", flag: "🇧🇴", country_code: "BO"},
    {phone: "+387", country: "Bosnia and Herzegovina", flag: "🇧🇦", country_code: "BA"},
    {phone: "+267", country: "Botswana", flag: "🇧🇼", country_code: "BW"},
    {phone: "+55", country: "Brazil", flag: "🇧🇷", country_code: "BR"},
    {phone: "+673", country: "Brunei", flag: "🇧🇳", country_code: "BN"},
    {phone: "+359", country: "Bulgaria", flag: "🇧🇬", country_code: "BG"},
    {phone: "+226", country: "Burkina Faso", flag: "🇧🇫", country_code: "BF"},
    {phone: "+257", country: "Burundi", flag: "🇧🇮", country_code: "BI"},
    {phone: "+855", country: "Cambodia", flag: "🇰🇭", country_code: "KH"},
    {phone: "+237", country: "Cameroon", flag: "🇨🇲", country_code: "CM"},
    {phone: "+238", country: "Cape Verde", flag: "🇨🇻", country_code: "CV"},
    {phone: "+236", country: "Central African Republic", flag: "🇨🇫", country_code: "CF"},
    {phone: "+235", country: "Chad", flag: "🇹🇩", country_code: "TD"},
    {phone: "+56", country: "Chile", flag: "🇨🇱", country_code: "CL"},
    {phone: "+86", country: "China", flag: "🇨🇳", country_code: "CN"},
    {phone: "+57", country: "Colombia", flag: "🇨🇴", country_code: "CO"},
    {phone: "+269", country: "Comoros", flag: "🇰🇲", country_code: "KM"},
    {phone: "+242", country: "Congo - Brazzaville", flag: "🇨🇬", country_code: "CG"},
    {phone: "+243", country: "Congo - Kinshasa", flag: "🇨🇩", country_code: "CD"},
    {phone: "+506", country: "Costa Rica", flag: "🇨🇷", country_code: "CR"},
    {phone: "+225", country: "Côte d'Ivoire", flag: "🇨🇮", country_code: "CI"},
    {phone: "+385", country: "Croatia", flag: "🇭🇷", country_code: "HR"},
    {phone: "+53", country: "Cuba", flag: "🇨🇺", country_code: "CU"},
    {phone: "+357", country: "Cyprus", flag: "🇨🇾", country_code: "CY"},
    {phone: "+420", country: "Czech Republic", flag: "🇨🇿", country_code: "CZ"},
    {phone: "+45", country: "Denmark", flag: "🇩🇰", country_code: "DK"},
    {phone: "+253", country: "Djibouti", flag: "🇩🇯", country_code: "DJ"},
    {phone: "+1", country: "Dominica", flag: "🇩🇲", country_code: "DM"},
    {phone: "+1", country: "Dominican Republic", flag: "🇩🇴", country_code: "DO"},
    {phone: "+593", country: "Ecuador", flag: "🇪🇨", country_code: "EC"},
    {phone: "+20", country: "Egypt", flag: "🇪🇬", country_code: "EG"},
    {phone: "+503", country: "El Salvador", flag: "🇸🇻", country_code: "SV"},
    {phone: "+240", country: "Equatorial Guinea", flag: "🇬🇶", country_code: "GQ"},
    {phone: "+291", country: "Eritrea", flag: "🇪🇷", country_code: "ER"},
    {phone: "+372", country: "Estonia", flag: "🇪🇪", country_code: "EE"},
    {phone: "+251", country: "Ethiopia", flag: "🇪🇹", country_code: "ET"},
    {phone: "+500", country: "Falkland Islands", flag: "🇫🇰", country_code: "FK"},
    {phone: "+298", country: "Faroe Islands", flag: "🇫🇴", country_code: "FO"},
    {phone: "+679", country: "Fiji", flag: "🇫🇯", country_code: "FJ"},
    {phone: "+358", country: "Finland", flag: "🇫🇮", country_code: "FI"},
    {phone: "+594", country: "French Guiana", flag: "🇬🇫", country_code: "GF"},
    {phone: "+689", country: "French Polynesia", flag: "🇵🇫", country_code: "PF"},
    {phone: "+241", country: "Gabon", flag: "🇬🇦", country_code: "GA"},
    {phone: "+220", country: "Gambia", flag: "🇬🇲", country_code: "GM"},
    {phone: "+995", country: "Georgia", flag: "🇬🇪", country_code: "GE"},
    {phone: "+49", country: "Germany", flag: "🇩🇪", country_code: "DE"},
    {phone: "+233", country: "Ghana", flag: "🇬🇭", country_code: "GH"},
    {phone: "+350", country: "Gibraltar", flag: "🇬🇮", country_code: "GI"},
    {phone: "+30", country: "Greece", flag: "🇬🇷", country_code: "GR"},
    {phone: "+299", country: "Greenland", flag: "🇬🇱", country_code: "GL"},
    {phone: "+1", country: "Grenada", flag: "🇬🇩", country_code: "GD"},
    {phone: "+590", country: "Guadeloupe", flag: "🇬🇵", country_code: "GP"},
    {phone: "+1", country: "Guam", flag: "🇬🇺", country_code: "GU"},
    {phone: "+502", country: "Guatemala", flag: "🇬🇹", country_code: "GT"},
    {phone: "+224", country: "Guinea", flag: "🇬🇳", country_code: "GN"},
    {phone: "+245", country: "Guinea-Bissau", flag: "🇬🇼", country_code: "GW"},
    {phone: "+592", country: "Guyana", flag: "🇬🇾", country_code: "GY"},
    {phone: "+509", country: "Haiti", flag: "🇭🇹", country_code: "HT"},
    {phone: "+504", country: "Honduras", flag: "🇭🇳", country_code: "HN"},
    {phone: "+852", country: "Hong Kong SAR China", flag: "🇭🇰", country_code: "HK"},
    {phone: "+36", country: "Hungary", flag: "🇭🇺", country_code: "HU"},
    {phone: "+354", country: "Iceland", flag: "🇮🇸", country_code: "IS"},
    {phone: "+91", country: "India", flag: "🇮🇳", country_code: "IN"},
    {phone: "+62", country: "Indonesia", flag: "🇮🇩", country_code: "ID"},
    {phone: "+98", country: "Iran", flag: "🇮🇷", country_code: "IR"},
    {phone: "+964", country: "Iraq", flag: "🇮🇶", country_code: "IQ"},
    {phone: "+972", country: "Israel", flag: "🇮🇱", country_code: "IL"},
    {phone: "+39", country: "Italy", flag: "🇮🇹", country_code: "IT"},
    {phone: "+1", country: "Jamaica", flag: "🇯🇲", country_code: "JM"},
    {phone: "+81", country: "Japan", flag: "🇯🇵", country_code: "JP"},
    {phone: "+962", country: "Jordan", flag: "🇯🇴", country_code: "JO"},
    {phone: "+7", country: "Kazakhstan", flag: "🇰🇿", country_code: "KZ"},
    {phone: "+254", country: "Kenya", flag: "🇰🇪", country_code: "KE"},
    {phone: "+686", country: "Kiribati", flag: "🇰🇮", country_code: "KI"},
    {phone: "+383", country: "Kosovo", flag: "🇽🇰", country_code: "XK"},
    {phone: "+965", country: "Kuwait", flag: "🇰🇼", country_code: "KW"},
    {phone: "+996", country: "Kyrgyzstan", flag: "🇰🇬", country_code: "KG"},
    {phone: "+856", country: "Laos", flag: "🇱🇦", country_code: "LA"},
    {phone: "+371", country: "Latvia", flag: "🇱🇻", country_code: "LV"},
    {phone: "+961", country: "Lebanon", flag: "🇱🇧", country_code: "LB"},
    {phone: "+266", country: "Lesotho", flag: "🇱🇸", country_code: "LS"},
    {phone: "+231", country: "Liberia", flag: "🇱🇷", country_code: "LR"},
    {phone: "+218", country: "Libya", flag: "🇱🇾", country_code: "LY"},
    {phone: "+423", country: "Liechtenstein", flag: "🇱🇮", country_code: "LI"},
    {phone: "+370", country: "Lithuania", flag: "🇱🇹", country_code: "LT"},
    {phone: "+352", country: "Luxembourg", flag: "🇱🇺", country_code: "LU"},
    {phone: "+853", country: "Macau SAR China", flag: "🇲🇴", country_code: "MO"},
    {phone: "+261", country: "Madagascar", flag: "🇲🇬", country_code: "MG"},
    {phone: "+265", country: "Malawi", flag: "🇲🇼", country_code: "MW"},
    {phone: "+60", country: "Malaysia", flag: "🇲🇾", country_code: "MY"},
    {phone: "+960", country: "Maldives", flag: "🇲🇻", country_code: "MV"},
    {phone: "+223", country: "Mali", flag: "🇲🇱", country_code: "ML"},
    {phone: "+356", country: "Malta", flag: "🇲🇹", country_code: "MT"},
    {phone: "+692", country: "Marshall Islands", flag: "🇲🇭", country_code: "MH"},
    {phone: "+596", country: "Martinique", flag: "🇲🇶", country_code: "MQ"},
    {phone: "+222", country: "Mauritania", flag: "🇲🇷", country_code: "MR"},
    {phone: "+230", country: "Mauritius", flag: "🇲🇺", country_code: "MU"},
    {phone: "+262", country: "Mayotte", flag: "🇾🇹", country_code: "YT"},
    {phone: "+52", country: "Mexico", flag: "🇲🇽", country_code: "MX"},
    {phone: "+691", country: "Micronesia", flag: "🇫🇲", country_code: "FM"},
    {phone: "+373", country: "Moldova", flag: "🇲🇩", country_code: "MD"},
    {phone: "+377", country: "Monaco", flag: "🇲🇨", country_code: "MC"},
    {phone: "+976", country: "Mongolia", flag: "🇲🇳", country_code: "MN"},
    {phone: "+382", country: "Montenegro", flag: "🇲🇪", country_code: "ME"},
    {phone: "+1", country: "Montserrat", flag: "🇲🇸", country_code: "MS"},
    {phone: "+212", country: "Morocco", flag: "🇲🇦", country_code: "MA"},
    {phone: "+258", country: "Mozambique", flag: "🇲🇿", country_code: "MZ"},
    {phone: "+95", country: "Myanmar (Burma)", flag: "🇲🇲", country_code: "MM"},
    {phone: "+264", country: "Namibia", flag: "🇳🇦", country_code: "NA"},
    {phone: "+674", country: "Nauru", flag: "🇳🇷", country_code: "NR"},
    {phone: "+977", country: "Nepal", flag: "🇳🇵", country_code: "NP"},
    {phone: "+31", country: "Netherlands", flag: "🇳🇱", country_code: "NL"},
    {phone: "+687", country: "New Caledonia", flag: "🇳🇨", country_code: "NC"},
    {phone: "+505", country: "Nicaragua", flag: "🇳🇮", country_code: "NI"},
    {phone: "+227", country: "Niger", flag: "🇳🇪", country_code: "NE"},
    {phone: "+234", country: "Nigeria", flag: "🇳🇬", country_code: "NG"},
    {phone: "+683", country: "Niue", flag: "🇳🇺", country_code: "NU"},
    {phone: "+672", country: "Norfolk Island", flag: "🇳🇫", country_code: "NF"},
    {phone: "+850", country: "North Korea", flag: "🇰🇵", country_code: "KP"},
    {phone: "+47", country: "Norway", flag: "🇳🇴", country_code: "NO"},
    {phone: "+968", country: "Oman", flag: "🇴🇲", country_code: "OM"},
    {phone: "+92", country: "Pakistan", flag: "🇵🇰", country_code: "PK"},
    {phone: "+680", country: "Palau", flag: "🇵🇼", country_code: "PW"},
    {phone: "+970", country: "Palestinian Territories", flag: "🇵🇸", country_code: "PS"},
    {phone: "+507", country: "Panama", flag: "🇵🇦", country_code: "PA"},
    {phone: "+675", country: "Papua New Guinea", flag: "🇵🇬", country_code: "PG"},
    {phone: "+595", country: "Paraguay", flag: "🇵🇾", country_code: "PY"},
    {phone: "+51", country: "Peru", flag: "🇵🇪", country_code: "PE"},
    {phone: "+63", country: "Philippines", flag: "🇵🇭", country_code: "PH"},
    {phone: "+48", country: "Poland", flag: "🇵🇱", country_code: "PL"},
    {phone: "+351", country: "Portugal", flag: "🇵🇹", country_code: "PT"},
    {phone: "+1", country: "Puerto Rico", flag: "🇵🇷", country_code: "PR"},
    {phone: "+974", country: "Qatar", flag: "🇶🇦", country_code: "QA"},
    {phone: "+242", country: "Republic of the Congo", flag: "🇨🇬", country_code: "CG"},
    {phone: "+40", country: "Romania", flag: "🇷🇴", country_code: "RO"},
    {phone: "+7", country: "Russia", flag: "🇷🇺", country_code: "RU"},
    {phone: "+250", country: "Rwanda", flag: "🇷🇼", country_code: "RW"},
    {phone: "+262", country: "Réunion", flag: "🇷🇪", country_code: "RE"},
    {phone: "+590", country: "Saint Barthélemy", flag: "🇧🇱", country_code: "BL"},
    {phone: "+290", country: "Saint Helena", flag: "🇸🇭", country_code: "SH"},
    {phone: "+1", country: "Saint Kitts and Nevis", flag: "🇰🇳", country_code: "KN"},
    {phone: "+1", country: "Saint Lucia", flag: "🇱🇨", country_code: "LC"},
    {phone: "+590", country: "Saint Martin", flag: "🇲🇫", country_code: "MF"},
    {phone: "+508", country: "Saint Pierre and Miquelon", flag: "🇵🇲", country_code: "PM"},
    {phone: "+1", country: "Saint Vincent and the Grenadines", flag: "🇻🇨", country_code: "VC"},
    {phone: "+685", country: "Samoa", flag: "🇼🇸", country_code: "WS"},
    {phone: "+378", country: "San Marino", flag: "🇸🇲", country_code: "SM"},
    {phone: "+239", country: "São Tomé and Príncipe", flag: "🇸🇹", country_code: "ST"},
    {phone: "+966", country: "Saudi Arabia", flag: "🇸🇦", country_code: "SA"},
    {phone: "+221", country: "Senegal", flag: "🇸🇳", country_code: "SN"},
    {phone: "+381", country: "Serbia", flag: "🇷🇸", country_code: "RS"},
    {phone: "+248", country: "Seychelles", flag: "🇸🇨", country_code: "SC"},
    {phone: "+232", country: "Sierra Leone", flag: "🇸🇱", country_code: "SL"},
    {phone: "+65", country: "Singapore", flag: "🇸🇬", country_code: "SG"},
    {phone: "+1", country: "Sint Maarten", flag: "🇸🇽", country_code: "SX"},
    {phone: "+421", country: "Slovakia", flag: "🇸🇰", country_code: "SK"},
    {phone: "+386", country: "Slovenia", flag: "🇸🇮", country_code: "SI"},
    {phone: "+677", country: "Solomon Islands", flag: "🇸🇧", country_code: "SB"},
    {phone: "+252", country: "Somalia", flag: "🇸🇴", country_code: "SO"},
    {phone: "+27", country: "South Africa", flag: "🇿🇦", country_code: "ZA"},
    {phone: "+500", country: "South Georgia & South Sandwich Islands", flag: "🇬🇸", country_code: "GS"},
    {phone: "+82", country: "South Korea", flag: "🇰🇷", country_code: "KR"},
    {phone: "+211", country: "South Sudan", flag: "🇸🇸", country_code: "SS"},
    {phone: "+34", country: "Spain", flag: "🇪🇸", country_code: "ES"},
    {phone: "+94", country: "Sri Lanka", flag: "🇱🇰", country_code: "LK"},
    {phone: "+249", country: "Sudan", flag: "🇸🇩", country_code: "SD"},
    {phone: "+597", country: "Suriname", flag: "🇸🇷", country_code: "SR"},
    {phone: "+47", country: "Svalbard and Jan Mayen", flag: "🇸🇯", country_code: "SJ"},
    {phone: "+268", country: "Swaziland (Eswatini)", flag: "🇸🇿", country_code: "SZ"},
    {phone: "+46", country: "Sweden", flag: "🇸🇪", country_code: "SE"},
    {phone: "+41", country: "Switzerland", flag: "🇨🇭", country_code: "CH"},
    {phone: "+963", country: "Syria", flag: "🇸🇾", country_code: "SY"},
    {phone: "+886", country: "Taiwan", flag: "🇹🇼", country_code: "TW"},
    {phone: "+992", country: "Tajikistan", flag: "🇹🇯", country_code: "TJ"},
    {phone: "+255", country: "Tanzania", flag: "🇹🇿", country_code: "TZ"},
    {phone: "+66", country: "Thailand", flag: "🇹🇭", country_code: "TH"},
    {phone: "+670", country: "Timor-Leste", flag: "🇹🇱", country_code: "TL"},
    {phone: "+228", country: "Togo", flag: "🇹🇬", country_code: "TG"},
    {phone: "+690", country: "Tokelau", flag: "🇹🇰", country_code: "TK"},
    {phone: "+676", country: "Tonga", flag: "🇹🇴", country_code: "TO"},
    {phone: "+1", country: "Trinidad and Tobago", flag: "🇹🇹", country_code: "TT"},
    {phone: "+216", country: "Tunisia", flag: "🇹🇳", country_code: "TN"},
    {phone: "+90", country: "Turkey", flag: "🇹🇷", country_code: "TR"},
    {phone: "+993", country: "Turkmenistan", flag: "🇹🇲", country_code: "TM"},
    {phone: "+1", country: "Turks and Caicos Islands", flag: "🇹🇨", country_code: "TC"},
    {phone: "+688", country: "Tuvalu", flag: "🇹🇻", country_code: "TV"},
    {phone: "+256", country: "Uganda", flag: "🇺🇬", country_code: "UG"},
    {phone: "+380", country: "Ukraine", flag: "🇺🇦", country_code: "UA"},
    {phone: "+971", country: "United Arab Emirates", flag: "🇦🇪", country_code: "AE"},
    {phone: "+598", country: "Uruguay", flag: "🇺🇾", country_code: "UY"},
    {phone: "+998", country: "Uzbekistan", flag: "🇺🇿", country_code: "UZ"},
    {phone: "+678", country: "Vanuatu", flag: "🇻🇺", country_code: "VU"},
    {phone: "+39", country: "Vatican City", flag: "🇻🇦", country_code: "VA"},
    {phone: "+58", country: "Venezuela", flag: "🇻🇪", country_code: "VE"},
    {phone: "+84", country: "Vietnam", flag: "🇻🇳", country_code: "VN"},
    {phone: "+681", country: "Wallis and Futuna", flag: "🇼🇫", country_code: "WF"},
    {phone: "+212", country: "Western Sahara", flag: "🇪🇭", country_code: "EH"},
    {phone: "+967", country: "Yemen", flag: "🇾🇪", country_code: "YE"},
    {phone: "+260", country: "Zambia", flag: "🇿🇲", country_code: "ZM"},
    {phone: "+263", country: "Zimbabwe", flag: "🇿🇼", country_code: "ZW"}
  ];

  export const breakpointSm = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-sm');
  export const breakpointMd = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md');
  export const breakpointLg = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-lg');
  export const breakpointXl = getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xl');
  