import React from 'react';
import { useCarouselControl } from '../FlashbulbUI/FBCarousel/FBCarouselContext';
import { 
    FormWrapper, 
    TextInput, 
    PhoneInput, 
    FormGroup, 
    CountryInput, 
    SubmitButton 
} from '../FlashbulbUI/Form'
import { Container } from '../FlashbulbUI/Container';
import { FlashbulbFormProvider } from '../FlashbulbUI/Form';
import { Hero } from '../FlashbulbUI/Hero';
import abstractbg from '../../assets/backgrounds/abstract.webp'
import { Link } from 'react-router-dom';

const ImageCaptionSlide = ({children, image, content}) => {

    return (
        <Link style={{color:'#333',  overflow:'hidden', height:'320px'}} to={content.link}><div style={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <div style={
                {
                    backgroundImage: `url('${content.image}')`,
                    backgroundSize:'cover',
                    backgroundPosition: 'center center',
                    border:'10px solid white', 
                    marginTop: '16px',
                    marginBottom: '16px',
                    boxShadow: '3px 3px 10px 1px #e2e2e2',
                    height:'150px', 
                    width:'200px',
                    whiteSpace: 'nowrap'
                }
            }>
                {children}
            </div>
            <div style={{fontSize: '12px', padding:'4px', width:'200px'}}>
                <div style={{fontSize:'16px', fontWeight:600}}>{content.title}</div>
                <p className="truncate-multiline">{content.description}</p>
            </div>
        </div>
        </Link>
    );

}

export default ImageCaptionSlide;