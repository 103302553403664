import React from 'react';
import { ContentSection } from '../../FlashbulbUI/Section';
import { FBParallaxSection } from '../../FlashbulbUI/FBParallaxSection';
import { Title } from '../../Title';
import { Hero, HeroTitle } from '../../FlashbulbUI/Hero';
import { Row, Col } from '../../FlashbulbUI/Layout'
import { TeamCard } from '../../FlashbulbUI/TeamCard';
import { Container } from '../../FlashbulbUI/Container';

const Vote = () => {

    return (
        <>
        <Hero>
            <HeroTitle type="page">Our Guide to Voting Abroad</HeroTitle>
        </Hero>
        <ContentSection>
            <h3 style={{textAlign:'left'}}>It's important to vote</h3>
            <p>It only takes 5 minutes to apply to vote by mail, you can do so by clicking here!</p>

            <p>The process is simple and straightforward. Canadian citizens who live abroad may apply to be on the International Register of Electors, which will allow them to vote by mail-in special ballot in federal general elections, by-elections, and referendums.</p>

            <p>If your application is accepted:</p>
            <ul>
                <li style={{marginLeft:'40px'}}>Elections Canada will send you a confirmation letter and add your name to the International Register of Electors, the list of Canadian electors living outside Canada who are eligible to vote by mail.</li>
                <li style={{marginLeft:'40px'}}>At each election in which you are qualified to vote, Elections Canada will automatically mail a special ballot voting kit to the address you provided.</li>
                <li style={{marginLeft:'40px'}}>Your name will stay on the International Register of Electors as long as you remain eligible. You will not have to re-apply to vote by mail at each election.</li>
            </ul>
            <h3 style={{textAlign:'left'}}>Points to keep in mind</h3>
            <p>1. Make sure to have your last address before leaving Canada on hand</p>

            <p>It determines your electoral district. You will vote for a candidate in the riding containing this address.</p>
            <ul><li style={{marginLeft:'40px'}}>You must use a physical address. You cannot use a post office box or rural route, except in areas where no other physical address is available.</li></ul>

            <p>2. Choose the address where you want to receive your special ballot voting kit</p>

            <p>Most people provide their home address. If you prefer, you can use the address of a Canadian embassy or consulate as your mailing address, if you arrange it with them beforehand and give them your contact information.</p>

            <p>3. Prepare your supporting document(s)</p>

            <p>To apply online, you simply need ONE of these digital image(s) of your proof of identity:</p>
            <ul>
                <li style={{marginLeft:'40px'}}>Pages 2 and 3 of your Canadian passport,</li>
                <li style={{marginLeft:'40px'}}>Your Canadian citizenship certificate or card or</li>
                <li style={{marginLeft:'40px'}}>Your birth certificate, showing that you were born in Canada.</li>
            </ul>
            <p>The images can be scans or digital photos, with each file having a size under 2.5 MB. PDF or any image file type (JPEG, GIF, TIF, PNG, etc.) are accepted. </p>
        </ContentSection>
        </>

    )

}

export default Vote;