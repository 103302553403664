import React, { useState, useEffect } from 'react';
import { Container } from '../FlashbulbUI/Container';
import '../../components/FlashbulbUI/Hero/hero.scss'
import { breakpointSm, breakpointMd, breakpointLg } from '../../utils/utility';

const HeroPierre = ({
    className = '', 
    children, 
    id, 
    height, 
    image = 'https://flashbulb-files.s3.amazonaws.com/images/hero.jpg',
    video,
    onClick,
    style
}) => {

    var breakpointLgValue = breakpointLg.replace('px', '') * 1;
    var breakpointMdValue = breakpointMd.replace('px', '') * 1;
    var breakpointSmValue = breakpointSm.replace('px', '') * 1;
    
    const [backgroundPosition, setBackgroundPosition] = useState('center right');

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > breakpointLgValue) {
            setBackgroundPosition('center right');
        } else if (window.innerWidth > breakpointMdValue && window.innerWidth <= breakpointLgValue) {
            setBackgroundPosition('center left');
        } else if (window.innerWidth > breakpointSmValue && window.innerWidth <= breakpointMdValue) {
            setBackgroundPosition('center left');
        } else if (window.innerWidth < breakpointSmValue) {
            setBackgroundPosition('center left');
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const baseStyle = {
        backgroundImage: image ? `url(${image})` : undefined,
        backgroundPosition: backgroundPosition,
        height: height ? `${height}px` : undefined,
    };

    const finalStyle = { ...baseStyle, ...style };

    return (
        <div className={`hero ${className}`} id={id} onClick={onClick} style={finalStyle}>
            {video && (
                <video autoPlay loop muted playsInline>
                    <source src={video} />
                </video>
            )}
            <Container>
                {children}
            </Container>
        </div>
    );
}

export default HeroPierre;
