import React from 'react';
import { marked } from 'marked';

const convertMarkdownToHtml = markdown => {
    return marked(markdown, { sanitize: true });
};
    
const MarkdownComponent = ({ markdownText }) => {
    const htmlContent = convertMarkdownToHtml(markdownText);

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

export default MarkdownComponent;