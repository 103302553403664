import React from 'react';
import { ContentSection } from '../../FlashbulbUI/Section';
import { FBParallaxSection } from '../../FlashbulbUI/FBParallaxSection';
import { Title } from '../../Title';
import { Hero, HeroTitle } from '../../FlashbulbUI/Hero';
import { Row, Col } from '../../FlashbulbUI/Layout'
import { TeamCard } from '../../FlashbulbUI/TeamCard';
import { Container } from '../../FlashbulbUI/Container';
import johnbaird from '../../../assets/custom/johnbaird.png'
import nigelwright from '../../../assets/custom/nigelwright.jpg'
import brettstephenson from '../../../assets/custom/brettstephenson.jpg'
import barrettbingley from '../../../assets/custom/barrettbingley.png'
import ianvaculik from '../../../assets/custom/ianvaculik.png'
import georganneburke from '../../../assets/custom/georganneburke.jpg'
import hermancheung from '../../../assets/custom/hermancheung.jpg'
import ccawordmark from '../../../assets/custom/cca-wordmark.png'
import ccamap from '../../../assets/custom/ccamap.png'
import logo from '../../../assets/custom/cca-logo.png'
import londonbg from '../../../assets/custom/londonbg.jpg'
import GoogleMap from './GoogleMap'

const About = () => {

    return (
        <>
        <Hero>
            <HeroTitle type="page">Canadian Conservatives Abroad</HeroTitle>
        </Hero>
        <ContentSection>
        <p>Based in London, UK with members from around the world, Canadian Conservatives Abroad is a grassroots organization dedicated to building a strong, free and fair Canada that defends and advances Canada’s interests on the world stage.</p>

<p>We are committed to providing expatriate Conservatives with meaningful networking opportunities; enabling their participation in Canadian elections; and serving as a resource for Conservative policy makers seeking real world insights from around the world.</p>
​​​​​​​
<p>Although we support the Conservative Party of Canada and Canada’s provincial conservative parties, we are financially and organizationally independent.</p>
​​​​​​​
<p>Through our activities we connect and empower Canadian Conservatives living abroad.</p>
        </ContentSection>
        <ContentSection type="alternate">
            <Row>
                <Col lg="12">
                    <h3 style={{textAlign:'left'}}>VISION</h3>
                    <p>Canadian Conservatives Abroad stands for a strong, free and fair Canada that defends and advances Canadian interests on the world stage.</p>

                    <h3 style={{textAlign:'left'}}>MISSION</h3>
                    <p>We are the premier organization for connecting and empowering expatriate Conservatives to contribute to Canada’s prosperity and influence while living abroad.</p>

                    <h3 style={{textAlign:'left'}}>PURPOSE</h3>
                    <p>We are committed to providing expatriate Conservatives with meaningful networking opportunities; enabling their participation in Canadian elections; and serving as a resource for Conservative policy makers seeking real world insights from around the world.</p>

                    <h3 style={{textAlign:'left'}}>MANTRA</h3>
                    <p>Connecting and Empowering Canadian Conservatives Abroad</p>
                </Col>
            </Row>
        </ContentSection>

        <FBParallaxSection image={londonbg} gradient={`linear-gradient(45deg, rgba(255, 255, 255, 0.6), rgba(232, 29, 45, 0.9), rgba(15, 45, 82, 0.9))`} height={1100} intensity={0.1}>
            <Container>
                <Row>
                    <Col lg="12">
                        <h2>Our Members Worldwide</h2>
                    </Col>
                </Row>

<Row>
    <Col lg="12">

                        <p>We have a growing international membership with existing membership hubs in the United States, United Kingdom, Germany, Belgium, Australia, and  Israel.</p> 
                        <p>We plan to grow our international membership and add membership hubs in cities and countries around the world.</p>
                        <p>If you're interested in heading up a chapter for Canadian Conservatives Abroad, get in touch.</p>


    </Col>
</Row>

                <Row>
                    <Col lg="12" style={{minHeight:'500px', }}>
                  
                    <GoogleMap style={{width:'100%'}} />
                    </Col>
                </Row>
            </Container>
        </FBParallaxSection>
        <ContentSection type="alternate">
            <Row>
                <Col lg="12">
                    <h2>Our Team</h2>
                    <h3>Officers</h3>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <TeamCard type="alternate" name="Hon. John Baird" title="Honourary President" linkedin="https://www.linkedin.com/in/john-r-baird-924196110/" image={johnbaird} />
                </Col>
                <Col lg="6">
                    <TeamCard type="alternate" name="Nigel Wright" title="Chair" linkedin="https://www.linkedin.com/in/nigel-wright-5612b38/" image={nigelwright} />
                </Col>
            </Row>
        </ContentSection>
        <ContentSection>
            <Row>
                <Col lg="6">
                    <TeamCard name="Brett Stephenson" title="Vice-Chair" linkedin="https://www.linkedin.com/in/brett-stephenson-%E9%BE%99%E6%95%8F%E5%88%9A-95693936/" image={brettstephenson} />
                </Col>
                <Col lg="6">
                    <TeamCard name="Barrett Bingley" title="Vice-Chair" linkedin="https://www.linkedin.com/in/bbingley/" image={barrettbingley} />
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <TeamCard name="Ian Vaculik" title="Treasurer" linkedin="https://www.linkedin.com/in/ian-vaculik-78975016/" image={ianvaculik} />
                </Col>
                <Col lg="6">
                    <TeamCard name="Georganne Burke" title="Secretary" linkedin="https://www.linkedin.com/in/georganne-burke-a5b3a22/" image={georganneburke} />
                </Col>
            </Row>
        </ContentSection>
        <ContentSection type="alternate">
            <Row>
                <Col lg="12">
                    <h3>Executive Committee Chair</h3>
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    <TeamCard type="alternate" name="Herman Cheung" title="Election Readiness" linkedin="https://www.linkedin.com/in/hermancheung/" image={hermancheung} />
                </Col>
            </Row>
        </ContentSection>
        <ContentSection>
            <Row>
                <Col lg="12">
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <img src={logo} height="200" />
                    </div>
                </Col>
            </Row>
        </ContentSection>
        </>

    )

}

export default About;