import React from 'react';
import { Row, Col } from '../FlashbulbUI/Layout';
import Container from '../FlashbulbUI/Container/Container';
import { Link } from 'react-router-dom';
import { 
    website_name,
    organization_name,
    organization_address1,
    organization_address2,
    organization_address_city,
    organization_address_state,
    organization_address_zip,
    organization_address_country,
    organization_facebook_username,
    organization_x_username,
    website_content
} from '../../utils/website';
import './footer.scss';
import facebook from '../../assets/social/facebook_white.svg';
import twitter from '../../assets/social/twitter_white.svg';
import linkedin from '../../assets/social/linked_white.svg'

const Footer = ({className, style}) => {

    const currentYear = new Date().getFullYear();

    return (
        <div className={`footer ${className ? className : ''}`}>
            <Container>
                <Row>
                    <Col lg="2" md="12" sm="12">
                        <div className="social_links">
                            <h4>Connect</h4>
                            <div className="social_icon_row">
                                <a href={`https://www.facebook.com/ConservativesAbroad`}><img width="32" alt="Facebook" src={facebook} /></a>
                                <a href={`https://www.twitter.com/conservaabroad`}><img width="32" alt="Twitter" src={twitter} /></a>
                                <a href={`https://www.linkedin.com/company/canadian-conservatives-abroad/`}><img width="32" alt="LinkedIn" src={linkedin} /></a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6" md="12" sm="12">
                        <div className="latest_articles">
                            <h4>Latest Articles</h4>
                            <ul>
                                {website_content.map((category, index) => (
                                    <React.Fragment key={index} >
                                        { category.content.slice(0, 3).map((page, pageIndex) => (
                                            <li key={`${index}-${pageIndex}`}><Link to={`/${category.slug}/${page.slug}`}>{page.title}</Link></li>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </Col>
                    <Col lg="4" md="12" sm="12">
                        <div className="organization_details">
                            <h4>Canadian Conservatives Abroad</h4>
                            <div>London</div>
                            <div>United Kingdom</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="copyright">© Canadian Conservatives Abroad - All rights reserved</div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    )

}

export default Footer;