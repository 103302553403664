import React from "react";
import linkedinIcon from '../../../assets/social/linked_white.svg'
import linkedinIconBlack from '../../../assets/social/linkedin_black.svg'

const TeamCard = ({type, name, image, title, linkedin, children}) => {

    return (
        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', gap:'1rem', alignContent:'center', alignItems:'center'}}>
            <div style={{display:'flex', gap:'1rem', justifyContent:'center'}}>
                <div style={{fontSize:'24px'}}>{name}</div>
                <div style={{display:'flex', alignContent:'center', flexWrap:'wrap'}}>
                    <a href={`${linkedin}`}>
                        <img width="32" height="32" alt="LinkedIn" src={type === "alternate" ? linkedinIcon : linkedinIconBlack } />
                    </a>
                </div>
            </div>
            <div style={{
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                borderRadius: '30px',
                width: '50%',
                height: '260px'
            }} />
            <div>{title}</div>
        </div>
    )

}

export default TeamCard;