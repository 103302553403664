import React from 'react';
import './image.scss';

const Image = ({src, minHeight = '200px'}) => {

    var style = {};

    style.backgroundImage = src ? `url("${src}")` : 'none';
    style.backgroundSize = 'cover';
    style.backgroundRepeat = 'no-repeat';
    style.backgroundPosition = 'center';
    style.width = "100%";
    style.minHeight = minHeight;


    return (
        <div style={style} />
    )

}

export default Image;