import React from 'react';
import './hero.scss';

const HeroDate = ({children, type}) => {

    return (
        <>
        <div className="heroDate">{children}</div>
        </>
    )

}

export default HeroDate;