import React from 'react';
import { useCarouselControl } from './FBCarouselContext';

const FBCarouselItem = ({ content, style }) => {
  const { pauseCarousel, playCarousel } = useCarouselControl();

  return (
    <div style={style}>
      {content}
    </div>
  );
};

export default FBCarouselItem;
