import React, { createContext, useContext } from 'react';

const FBCarouselControlContext = createContext();

export const useCarouselControl = () => useContext(FBCarouselControlContext);

export const FBCarouselControlProvider = ({ children, carouselRef }) => {
  const pauseCarousel = () => {
    carouselRef.current.slickPause();
  };

  const playCarousel = () => {
    carouselRef.current.slickPlay();
  };

  return (
    <FBCarouselControlContext.Provider value={{ pauseCarousel, playCarousel }}>
      {children}
    </FBCarouselControlContext.Provider>
  );
};
