import React from 'react';
import { Container } from '../Container';
import './section.scss';

const ContentSection = ({children, type, size}) => {

    return (
        <section className={type === "alternate" ? "content-section-alternate" : "content-section-standard"}>
            <Container size={size ? size : "lg"}>
                {children}
            </Container>
        </section>
    )

}

export default ContentSection;