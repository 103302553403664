import React from 'react';

const FBAccordionItem = ({ title, children, isOpen, onClick }) => {
    return (
      <div className="accordion-item">
        <button className="accordion-title" onClick={onClick}>
          {title}
          <span className="indicator-icon">{isOpen ? '-' : '+'}</span>
        </button>
        <div className={`accordion-content ${isOpen ? 'open' : ''}`}>
          {children}
        </div>
      </div>
    );
};
  
export default FBAccordionItem;