import React from 'react';
import './layout.scss';

const Row = ({children}) => {

    return (
        <div className="row">
            {children}
        </div>
    )

}

export default Row;