import React, { useContext } from 'react';
import { Button } from '../../Button'
import { FormDataContext } from '../../FlashbulbFormProvider/FlashbulbFormProvider';

const SubmitButton = ({color, label, className, style, disabled}) => {

    const { onSubmitHandler } = useContext(FormDataContext);

    return (
        <Button 
            type="submit" 
            className={className} 
            style={style} 
            color={color} 
            disabled={disabled}
            onClick={onSubmitHandler}>
                {label}
        </Button>
    )

}

export default SubmitButton;