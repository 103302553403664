import React, { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import { SliderControlProvider } from './FBSliderContext';
import Slide from './FBSlide';
import './fbslider.scss'

const FBSlider = ({ settings, slides, className, style, onSliderChange }) => {
    
    const defaultSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        ...settings
      };

      const sliderRef = useRef();

      const renderSlides = () => {
        if (!slides || slides.length === 0) {
          return <div>No slides available</div>;
        }
    
        return slides.map((slide, i) => (
            <Slide key={i} content={slide.content} />
        ));
      };
  
      return (
        <SliderControlProvider sliderRef={sliderRef}>
            <div className={`${className}`} style={style}>
                <Slider ref={sliderRef} {...defaultSettings} {...(onSliderChange && { beforeChange: onSliderChange })}>   
                    {renderSlides()}
                </Slider>
            </div>
        </SliderControlProvider>
      );
  };

  FBSlider.propTypes = {
    settings: PropTypes.object,
    slides: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.node.isRequired
    })),
    className: PropTypes.string,
    style: PropTypes.object,
    onSliderChange: PropTypes.func
  };
  
  FBSlider.defaultProps = {
    slides: [],
    className: '',
    style: {}
  };
  
  export default FBSlider;