import React from 'react';
import './form.scss';

const FormGroup = ({
    children, 
    xs, 
    sm, 
    md, 
    lg, 
    xl, 
    style
}) => {

    xs = xs || "12";
    sm = sm || xs;
    md = md || sm;
    lg = lg || md;
    xl = xl || lg;
    
    const classes = [
        `formGroup-xl-${xl}`,
        `formGroup-lg-${lg}`,
        `formGroup-md-${md}`,
        `formGroup-sm-${sm}`,
        `formGroup-xs-${xs}`
    ];

    return (
        <div className={classes.join(' ')} style={style}>
            {children}
        </div>
    )

}

export default FormGroup;