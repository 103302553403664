import React from "react";
import { Hero, HeroTitle } from '../../../components/FlashbulbUI/Hero'

const Thanks = () => {

    return (
        <Hero><HeroTitle>Thanks! Someone will be in touch!</HeroTitle></Hero>
    )

}

export default Thanks;