import React from 'react';
import { Container } from '../../FlashbulbUI/Container';
import { Link } from 'react-router-dom';
import { Hero, HeroTitle } from '../../FlashbulbUI/Hero'
import { Row, Col } from '../../FlashbulbUI/Layout'
import { Card, CardHeader, CardBody, CardFooter } from '../../Card';
import { Image } from '../../Image';
import './categoryContent.scss';
import { stripMarkdown } from '../../../utils/utility';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { useNavigate } from 'react-router-dom';
import { Footer } from '../../Footer';
import ContentSection from '../../FlashbulbUI/Section/ContentSection';

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

const CategoryPage = ({ categoryData }) => {
    
    const navigate = useNavigate();

    console.log(categoryData);    

    return (
        <>
        <Hero>
            <HeroTitle type="page">{categoryData.name}</HeroTitle>
        </Hero>
        <ContentSection size="lg">
            <div className="categoryContent" style={{gap:'2rem'}}>
                {categoryData.content.length > 0 ? (
                    <>
                        {categoryData.content.map((page, i) => (
                            <>
                            <div key={i} style={{display:'flex', gap:'2rem'}}>
                                    <div style={{flexGrow: 0, flexShrink: 0, borderRadius:'30px', backgroundImage:`url(${page.og_image})`, backgroundSize:'cover', backgroundPosition:'center center', width:'200px', height:'150px'}} />
                                    <div style={{flexGrow: 1, flexShrink: 1, flexBasis: 'auto'}}>
                                        <Link style={{fontSize:'24px'}} to={`/${categoryData.slug}/${page.slug}`}>{page.title}</Link>
                                        <div style={{marginTop:'1rem'}}>
                                            {stripMarkdown(page.body.substr(0,300))} ...
                                        </div>
                                    </div>
                                    </div>
                            </>

                        ))}
                    
                    </>
                ) : (
                    <></>
                )}

                <p>{categoryData.body}</p>
            </div>
        </ContentSection>
        </>
    );
}

export default CategoryPage;