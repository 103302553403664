import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { website_content } from '../../../utils/website';
import { Container } from '../Container';
import { Image } from '../../Image'
import './nav.scss';
import logo from '../../../assets/custom/cca-logo.png'
import { Link } from 'react-router-dom';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(prevState => !prevState);

  const renderNavLinks = () => (
    <>
    <li>
    <NavLink 
      to={`/about`} 
      className={({ isActive }) => isActive ? 'nav-active' : ''}
    >
      About
    </NavLink>
    <Link 
      to={`https://www.elections.ca/content.aspx?section=vot&dir=reg/etr&document=index&lang=e`} 
      className={({ isActive }) => isActive ? 'nav-active' : ''}
    >
      Vote
    </Link>
    <NavLink 
      to={`/events`} 
      className={({ isActive }) => isActive ? 'nav-active' : ''}
    >
      Events
    </NavLink>
  </li>
  { website_content && website_content.map((navItem, i) => (
      <li key={i}>
        <NavLink 
          to={`/${navItem.slug}`} 
          className={({ isActive }) => isActive ? 'nav-active' : ''}
        >
          {navItem.name}
        </NavLink>
      </li>
    ))}

  </>
  );

  return (
    <nav>
      <Container>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
        <div className="nav-container">
      <Link to={'/'}>
        { !isOpen ? 
        <div className="nav-logo">
          <img width="48" src={logo} />
        </div>
        : <></> }
      </Link>
      <ul className="main-nav">
        <li>
          <NavLink 
            to={'/'} 
            className={({ isActive }) => isActive ? 'nav-active' : ''}
          >
            Home
          </NavLink>
        </li>
        {renderNavLinks()}
      </ul>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <NavLink 
              to={'/'} 
              className={({ isActive }) => isActive ? 'nav-active' : ''}
            >
              Home
            </NavLink>
          </li>
          {renderNavLinks()}
        </ul>
        </div>
        </div>
      </Container>
    </nav>
  );
};

export default Nav;
