import React from 'react';
import './form.scss';

const Input = ({placeholder, name, onChange, onBlur, value, type, required, className, style}) => {

    return (
        <input required={required} type={type} name={name} value={value} onChange={onChange} onBlur={onBlur} placeholder={placeholder} className={`input ${className}`} style={style} />
    )

}

export default Input;