import websiteData from '../content/website.json';

var website_name = websiteData.website_name;
var website_domain = websiteData.website_domain;
var organization_address1 = websiteData.organization_address1;
var organization_address2 = websiteData.organization_address2;
var organization_address_city = websiteData.organization_address_city;
var organization_address_state = websiteData.organization_address_state;
var organization_address_zip = websiteData.organization_address_zip;
var organization_address_country = websiteData.organization_address_country;
var organization_name = websiteData.organization_name;
var organization_facebook_username = websiteData.organization_facebook_username;
var organization_x_username = websiteData.organization_x_username;
var organization_pinterest_username = websiteData.organization_pinterest_username;
var website_content = websiteData.content;

export {
    website_name,
    website_domain,
    organization_address1,
    organization_address2,
    organization_address_city,
    organization_address_state,
    organization_address_zip,
    organization_address_country,
    organization_name,
    organization_facebook_username,
    organization_x_username,
    organization_pinterest_username,
    website_content
}