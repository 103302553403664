import React, { useContext } from 'react';
import { useSliderControl } from '../FlashbulbUI/FBSlider/FBSliderContext';
import { 
    FormWrapper, 
    TextInput, 
    PhoneInput, 
    FormGroup, 
    CityInput,
    CountryInput, 
    SubmitButton 
} from '../FlashbulbUI/Form'
import { Container } from '../FlashbulbUI/Container';
import { FlashbulbFormProvider } from '../FlashbulbUI/Form';
import { Hero } from '../FlashbulbUI/Hero';
import { Row, Col } from '../FlashbulbUI/Layout/index'
import abstractbg from '../../assets/backgrounds/abstract.webp'
import pierrehero from '../../assets/custom/pierre-hero.jpg'
import { FormDataContext } from '../FlashbulbFormProvider/FlashbulbFormProvider';
import HeroPierre from './HeroPierre';

const FormSlide = () => {

    const { pauseSlider, playSlider } = useSliderControl();

    const { formData } = useContext(FormDataContext);

    return (
        <HeroPierre image={pierrehero}>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className="herotext">Canadian?<br />Conservative?<br />Living Abroad?</div>
                            <div className="herosubtext">Join our community of expats!</div>
                    <FormWrapper>
                        <FormGroup xs="6">
                            <TextInput 
                                label="First name"
                                labelColor="white" 
                                dataType="fname" 
                                required="true"
                                errorText="Please enter your first name" 
                            />
                        </FormGroup>
                        <FormGroup xs="6">
                            <TextInput 
                                label="Last name" 
                                labelColor="white"
                                dataType="lname" 
                                required="true"
                                errorText="Please enter your last name" 
                            />
                        </FormGroup>
                        <FormGroup lg="6">
                            <TextInput 
                                label="Email" 
                                labelColor="white"
                                dataType="email" 
                                required="true"
                                errorText="Please enter a valid email address" 
                            />
                        </FormGroup>
                        <FormGroup lg="6">
                            <CountryInput 
                                label="Country"
                                labelColor="white" 
                                required="true"
                            />
                        </FormGroup>
                        { formData && formData.country === "US" ? <FormGroup lg="12">
                            <CityInput 
                                label="Closest US city / region"
                                labelColor="white" 
                            />
                        </FormGroup>
                        : <></> }
                        <FormGroup>
                            <SubmitButton 
                                style={{backgroundColor:'#e81d2d', color:'white'}}
                                label="Sign up"
                            />
                        </FormGroup>
                    </FormWrapper>

                        </Col>
                        <Col lg="6">
                    </Col>
                    </Row>
                </Container>
        </HeroPierre>
    );

}

export default FormSlide;