import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import DynamicRoutes from './DynamicRoutes';
import Nav from './components/FlashbulbUI/Nav/Nav';
import { Footer } from './components/Footer';
import './style.scss';

function App() {
  return (
      <Router>
        <Nav />
        <DynamicRoutes />
        <Footer />
      </Router>

  );
}

export default App;