import React from 'react';
import './form.scss';

const ErrorLabel = ({children, className, style}) => {

    return (
        <div className={`errorLabel ${className}`} style={style}>
            {children}
        </div>
    )

}

export default ErrorLabel;