import React from 'react';
import './button.scss';

const Button = ({children, onClick, disabled, color, type, style}) => {

    var buttonColorClass = "";

    if (color === "primary") {
        buttonColorClass = "primary"
    } else if (color === "secondary") {
        buttonColorClass = "secondary"
    } else if (color === "success") {
        buttonColorClass = "success"
    } else if (color === "info") {
        buttonColorClass = "info"
    } else if (color === "warning") {
        buttonColorClass = "warning"
    } else if (color === "danger") {
        buttonColorClass = "danger"
    } else if (color === "light") {
        buttonColorClass = "light"
    } else if (color === "dark") {
        buttonColorClass = "dark"
    }

    return (
        <button style={style} type={type} onClick={onClick} disabled={disabled} className={`button ${buttonColorClass}`}>
            {children}
        </button>
    )

}

export default Button;