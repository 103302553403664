import React, { useEffect } from 'react';
import { ContentSection } from '../../FlashbulbUI/Section';
import { FBParallaxSection } from '../../FlashbulbUI/FBParallaxSection';
import { Title } from '../../Title';
import { Hero, HeroTitle } from '../../FlashbulbUI/Hero';
import { Row, Col } from '../../FlashbulbUI/Layout'
import { TeamCard } from '../../FlashbulbUI/TeamCard';
import { Container } from '../../FlashbulbUI/Container';

const Events = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "//platform.eventscalendar.co/plugins/web/loader.js?app=calendar";
        script.id = "Inffuse-Loader";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

<script type="text/javascript" src="//platform.eventscalendar.co/plugins/web/loader.js?app=calendar" id="Inffuse-Loader"></script>

    return (
        <>
        <Hero>
            <HeroTitle type="page">Events</HeroTitle>
        </Hero>
        <ContentSection>
            <h3 style={{textAlign:'left'}}>Upcoming Events</h3>
            <div className="app-placeholder-calendar" data-id="proj_nEw6CZWLjwESqELx2bN4S"></div>

        </ContentSection>
        </>

    )

}

export default Events;