import React from 'react';
import { Hero, HeroTitle } from './components/FlashbulbUI/Hero'
import { FBSlider } from './components/FlashbulbUI/FBSlider';
import { FBCarousel } from './components/FlashbulbUI/FBCarousel';
import { Container } from './components/FlashbulbUI/Container';
import { FBAccordion, FBAccordionItem } from './components/FlashbulbUI/FBAccordion';
import FormSlide from './components/CustomSlides/FormSlide';
import VideoSlide from './components/CustomSlides/VideoSlide';
import TextSlide from './components/CustomSlides/TextSlide';
import ImageSlide from './components/CustomSlides/ImageSlide';
import ImageCaptionSlide from './components/CustomSlides/ImageCaptionSlide'
import abstractbg from './assets/backgrounds/abstract.webp'
import { Footer } from './components/Footer';
import { FBParallaxSection } from './components/FlashbulbUI/FBParallaxSection';
import { Button } from './components/Button';
import { Row, Col } from './components/FlashbulbUI/Layout';
import ContentSection from './components/FlashbulbUI/Section/ContentSection';
import { Image } from './components/Image';
import votesign from './assets/custom/votesign.jpg'
import stephenharper from './assets/custom/stephen-harper.jpg'
import bairdwright from './assets/custom/bairdwright.webp'
import kingcharles from './assets/custom/kingcharles.jpg'
import ccacsf from './assets/custom/cca-csf.jpg'
import ccalondon from './assets/custom/ccalondon.jpg'
import ccapoilievre from './assets/custom/ccapoilievre.jpg'
import vets from './assets/custom/vets.jpg'
import xbg from './assets/custom/xbg.png'
import ccalondoncrop from './assets/custom/ccalondoncrop.jpg'
import { FlashbulbFormProvider } from './components/FlashbulbUI/Form';

const Home = () => {2000

    const header_slides = [
        { content: <FlashbulbFormProvider form_id="123"><FormSlide /></FlashbulbFormProvider> },
        { content: <TextSlide style={{textAlign:'center'}} image={xbg}><HeroTitle>Follow us on X</HeroTitle><Button onClick={() => window.location.href = 'https://x.com/conservaabroad'} color="dark">@conservaabroad</Button></TextSlide> },
    ];

    const content_slides = [
        { content: <ImageCaptionSlide content={{image:stephenharper, title:'Stephen Harper receives Alberta\'s highest honour', description:'Former prime minister Stephen Harper is among eight people to be named to the Alberta Order of Excellence later this year. ', link:'https://calgaryherald.com/news/local-news/stephen-harper-among-alberta-to-receive-provinces-highest-honour'}}/> },
        { content: <ImageCaptionSlide content={{image:bairdwright, title:'John Baird, Nigel Wright head up new group', description:'Baird said expats \'overwhelmingly\' don\'t vote in elections, and noted the estimated three million Canadians living abroad is equivalent to about 30 electoral ridings', link:'https://nationalpost.com/news/politics/john-baird-nigel-wright-head-up-new-group-to-organize-right-leaning-canadians-abroad'}}/> },
        { content: <ImageCaptionSlide content={{image:kingcharles, title:'Canadian Conservatives celebrate coronation', description:'The Canadian expatriate community of Conservatives celebrates the coronation of King Charles III. Long live the King! ', link:'https://www.facebook.com/ConservativesAbroad/posts/pfbid02JPq9NB2pqNsQiEjWA3VJb5yHGJxN7YkznhzBFVxACW8msN8xkKnpDShGEqazE8S1l'}}/> },
        { content: <ImageCaptionSlide content={{image:ccacsf, title:'Attending CSF conference in Ottawa', description:'Canadian Conservatives Abroad attended the Canada Strong and Free conference in Ottawa.', link:'https://www.facebook.com/ConservativesAbroad/posts/pfbid0BW84LPxkbR8d4Jd3Fvb2PXfC18RYz9czt8ikK9dTrBNeUEYJskQyABnB8iKPA3pvl'}}/> },
        { content: <ImageCaptionSlide content={{image:ccalondon, title:'Canadian Conservatives in London 🇨🇦🇬🇧 ', description:'Canadian Conservatives Abroad held a pub night for expat Canadian Conservatives in the UK', link:'https://www.facebook.com/ConservativesAbroad/posts/pfbid02teafM7AqjSPkKGJtxTUBmwEm7WUZVuaoKmRP2nFpugDRboDhwURFKYzKAVWetajdl'}}/> },
        { content: <ImageCaptionSlide content={{image:ccapoilievre, title:'CCA helping Pierre Poilievre become PM', description:'Canadian Conservatives Abroad helps expat Canadians vote Conservative', link:'https://www.facebook.com/photo/?fbid=310848201232168&set=pcb.310848351232153'}}/> },
        { content: <ImageCaptionSlide content={{image:vets, title:'Honouring those who served', description:'Veterans and their families have always been there for us in our time of need at home and abroad. Canadians must stand up for them in their time of need.', link:'https://www.facebook.com/PeterGMacKay/photos/a.10150884576168513/10157167441923513/'}}/> },
    ];

    const accordion_items = [
        { title: "What address/riding can I register in?", content: "You should use the last address you lived in Canada.  If this not possible, use an address where you have significant ongoing ties such as family." },
        { title: "Where can I find my riding and my candidate’s name?", content: "You must write the first and last name of your candidate on your ballot.  Do not write “Conservative Party” or “Pierre Poilievre” (unless you happen to be voting in Pierre Poilievre's riding of Carleton!)<br /><br /> The Conservative Party of Canada has a convenient way to <a href='https://www.conservative.ca/candidates/'>search for your riding and candidate here</a>. <br /><br />Elections Canada also maintains a page to <a href='https://www.elections.ca/scripts/vis/FindED?L=e&PAGEID=20'>find your riding and candidate here</a>." },
        { title: "Will this affect my tax status in Canada?", content: "No it will not.  CCA has received a qualified attorney’s opinion that voting from abroad will not affect your tax status in Canada. It does not make you ‘resident’. " },
        { title: "I have been away for more than 5 years, can I vote?", content: "Yes, the Supreme Court of Canada has ruled that all Canadians living overseas can vote, no matter how long they have been away from Canada.<br /><br />Additional FAQs on voting <a href='https://www.elections.ca/content.aspx?section=vot&dir=reg/etr&document=index&lang=e#etr7'>from Elections Canada found here</a>." },
    ]

    return (
        <>
            <FBSlider slides={header_slides} />
            <ContentSection>
                <FBCarousel slides={content_slides} />
            </ContentSection>
            <ContentSection type="alternate">
                <Row>
                    <Col sm="12" lg="12">
                        <h2 style={{textAlign:'left'}}>Connecting and Empowering Canadian Conservatives Abroad</h2>
                    </Col>
                    <Col sm="12" lg="6" style={{paddingRight:'2rem'}}>
                        <p>There are an estimated three million expatriate Canadians.</p>

                        <p>Work, family or adventure may have taken us abroad but we continue to care very much about the future of our country and the conservative parties we support.</p>

                        <p>Canadian Conservatives Abroad has been created for you. Based in the UK, we are a grassroots organization that promotes the interests of expatriate Conservatives. Although we support Canada’s federal and provincial conservative parties we are financially and operationally independent.</p>

                        <p>​​​​​​​By joining Canadian Conservatives Abroad you will:</p>
                        <ul>
                            ​<li>​​​​​​Join a growing worldwide network of expatriate Conservatives that provides opportunities for socializing and networking; access to exclusive events; and a subscription to our newsletter.</li>
                            <li>Receive information and support to help you vote in Canadian elections.</li>
                            <li>Provide opportunities to share real world insights from an expatriate perspective on Canadian policy, both domestic and foreign.</li>
                        </ul>
                    </Col>
                    <Col sm="12" lg="6">
                        <Image src={votesign} minHeight={500} />
                    </Col>
                </Row>
            </ContentSection>
            <FBParallaxSection height={200} image={ccalondoncrop} gradient={`linear-gradient(45deg, rgba(255, 255, 255, 0.6), rgba(232, 29, 45, 0.9), rgba(15, 45, 82, 0.9))`} intensity={0.1}>
                <Button style={{backgroundColor:'#0f2d52', color:'white'}}>Join our community!</Button>
            </FBParallaxSection>
            <ContentSection>
                <Container>
                    <Row>
                        <Col lg="12">
                            <h2 style={{textAlign:'left'}}>Election and Voting FAQ</h2>
                            <FBAccordion items={accordion_items} />
                        </Col>
                    </Row>
                </Container>
            </ContentSection>
            
        </>
    )
}

export default Home;