import React from 'react';
import './form.scss';

const FormWrapper = ({children, style, className}) => {

    return (
        <div className={`formWrapper ${className}`} style={style}>
            {children}
        </div>
    )

}

export default FormWrapper;